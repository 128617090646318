@import "shared/constant/scss/variables.scss";

.toc-row {
  display: flex;
  background: #ffffff;
  padding: 15px;
  font-family: "Mon-medium", sans-serif;
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #eef0f6;

  .toc-cell {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 20%;
    }
  }
  .pending-status {
    color: #b91d1d;
  }
  .icons {
    width: 25%;
    display: flex;
    justify-content: end;
    .icon-background {
      margin-right: 16px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 50%;
      &:hover {
        cursor: pointer;
      }
    }
    .edit-button {
      margin-right: 10px !important;
    }
  }
  &:hover {
    background: #eeeeee;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.toc-approve-info {
  display: flex;
  flex-flow: column;
  padding: 24px 32px;
  background: #eeeeee;
  .toc-plan-error {
    color: #ff5757;
    font-family: "Mon-medium", sans-serif;
    font-size: 12px;
    height: 30px;
    transform: translateY(-5px);
    span {
      display: flex;
      margin-bottom: 5px;
      img {
        margin-right: 6.5px;
        height: 15px;
        width: 15px;
      }
    }
  }
  .location-grid-container {
    width: 100%;
    margin-bottom: 24px;
    .location-dropdowns {
      .toc-empty-image {
        width: 100%;
        height: 224px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: #fff;
        margin-bottom: 16px;

        .empty-message-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 15px;
          .empty-message {
            color: #333;
            font-family: "Mon-bold", sans-serif;
            font-size: 14px;
            margin-bottom: 7px;
          }
          .empty-message-subline {
            color: #333;
            font-family: "Mon-medium", sans-serif;
            font-size: 14px;
          }
        }
      }
      .location-row-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 17px;
        align-items: center;
        font-size: 12px;
        font-family: "Mon-bold", sans-serif;
        .location {
          width: 70px;
        }

        .location-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-left: 25px;
          margin-right: 25px;
        }

        .preferred {
          color: #333;
          font-family: "Mon-medium", sans-serif !important;
          font-size: 12px;
        }

        .los {
          width: 100px;
          display: flex;
          justify-content: center;
        }
      }
      .read-only-footer {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        .preferred-text {
          color: #333;
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
        }
        width: 100%;
        .preferred-container {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
      .location-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 17px;
        .location-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          align-items: center;
          width: 100%;
          .location-value {
            font-size: 14px;
            color: #636d70;
            font-family: "Mon-medium", sans-serif;
            width: 70px;
          }
          .toc-dropdown {
            width: 100%;
            margin-left: 25px;
            margin-right: 25px;
          }
          .toc-visits {
            margin-right: 2px;
            .visits-input {
              background: #ffffff;
              mix-blend-mode: normal;
              opacity: 0.8;
              border: 1px solid #c1c9d2;
              border-radius: 8px;
              height: 42px;
              text-align: center;
              width: 60px;

              &:disabled {
                opacity: 0.5;
                border: 1px solid #c1c9d2;
                cursor: not-allowed;
              }
            }
            .input-error {
              border: 1px solid #ff5757;
            }
          }
        }
        .location-error {
          width: 100%;
          span {
            margin-top: 9px;
            color: #ff5757;
            font-family: "Mon-medium", sans-serif;
            display: flex;
            align-items: center;
            font-size: 12px;
            img {
              width: 15px;
              margin-right: 7px;
            }
          }
        }
      }
    }
    .checkbox-container {
      width: fit-content;
    }
  }

  .note-container {
    display: flex;
    order: 1;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
    .physician-notes {
      display: flex;
      flex-direction: column;
      // margin-top: 20px;
      width: 100%;
      .note-heading {
        margin-bottom: 5px;
        font-size: 12px;
        font-family: "Mon-bold", sans-serif;
      }
      .value {
        width: 100%;
        max-height: 120px;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        color: #333333;
        word-break: break-all;
        line-height: 14px;
      }
      .note-textarea {
        background: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.8;
        border: 1px solid #c1c9d2;
        border-radius: 8px;
        height: 120px;
        resize: none;
        padding: 12px 16px;
        font-family: "Mon-medium", sans-serif;
        color: #333333;
        line-height: 14px;
      }
      .note-hint {
        color: #636d70;
        font-family: "Mon-medium", sans-serif;
        text-align: end;
        font-size: 12px;
        margin-top: 05px;
        .note-limit {
          font-family: "Mon-bold", sans-serif;
        }
      }
    }
    .navigator-notes {
      width: 100%;
      .note-heading {
        margin-bottom: 5px;
        font-size: 12px;
        font-family: "Mon-bold", sans-serif;
      }
      .value {
        width: 100%;
        max-height: 120px;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        color: #333333;
        word-break: break-all;
        line-height: 14px;
      }
      .note-textarea-readonly {
        width: 100%;
        max-height: 120px;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        color: #333333;
        word-break: break-all;
        line-height: 14px;
      }
    }
  }

  .toc-plan {
    width: 100%;
    .care-plan-options-dropdown {
      width: 280px;
      margin-bottom: 8px;
    }
    .options-heading {
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 12px;
      color: #000;
      margin-bottom: 40px;
    }
    .option-box-wrapper {
      z-index: 3;
    }
    .toc-add-service-with-options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-service-button {
        width: 129px;
        height: 40px;
        color: #333;
        text-align: center;
        font-family: "Mon-medium", sans-serif;
        font-size: 16px;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }
      }

      .toc-plan-los-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 8px;

        .toc-plan-los {
          display: flex;
          align-items: center;
          flex-direction: row;
        }

        .acute-los-heading {
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 12px;
          color: #636d70;
          margin-right: 25px;
          margin-bottom: 4px;
          color: #000;
        }

        .acute-los {
          .actue-los-input {
            background: #ffffff;
            mix-blend-mode: normal;
            opacity: 0.8;
            border: 1px solid #c1c9d2;
            border-radius: 8px;
            height: 41.25px;
            text-align: center;
            width: 60px;

            &.input-error {
              border: 1px solid #ff5757;
            }
          }
        }
      }
    }
    .episode-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .toc-plan-heading {
        font-size: 18px;
        font-family: "Mon-bold", sans-serif;
        // margin-bottom: 24px;
      }
      .toc-plan-options {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .toc-plan-los-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 8px;

        .toc-plan-los {
          display: flex;
          align-items: center;
          flex-direction: row;
        }

        .acute-los-heading {
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
          color: #636d70;
          margin-right: 25px;
        }

        .acute-los {
          .actue-los-input {
            background: #ffffff;
            mix-blend-mode: normal;
            opacity: 0.8;
            border: 1px solid #c1c9d2;
            border-radius: 8px;
            height: 41.25px;
            text-align: center;
            width: 60px;

            &.input-error {
              border: 1px solid #ff5757;
            }
          }
        }

        .acute-los-readonly {
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
          color: #000000;
          display: flex;
          justify-content: end;
        }
      }
    }
  }
}
.last-row-with-approve-button {
  height: 64px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: end;
  background: #eeeeee;
  padding-right: 32px;

  .cta-container {
    .clear-button,
    .cancel-button {
      font-family: "Mon-medium", sans-serif;
      color: #333333;
      padding: 10px;
      width: 122px;
      height: 40px;
      font-size: 16px;
      cursor: pointer;
      margin: 0px 10px;
      &:disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .submit-button {
      height: 40px;
      width: 146px;
      font-family: "Mon-medium", sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin-left: 10px;
      cursor: pointer;

      &:disabled {
        pointer-events: none;
      }
    }
  }
}

.toc-approve-info-readonly {
  display: flex;
  flex-flow: column;
  padding: 24px 32px;
  background: #eeeeee;
  .note-episode-container {
    min-width: 250px;
    width: 45%;
    margin-top: 20px;
    margin-right: 60px;
    margin-bottom: 20px;
  }
  .note-container {
    display: flex;
    flex-direction: column;
    .revision-reasons {
      display: flex;
      justify-content: space-between;
    }
    .revision-reason {
      width: 45%;
      margin-top: 20px;
      .note-heading {
        margin-bottom: 5px;
        font-size: 12px;
        font-family: "Mon-bold", sans-serif;
      }
      .value {
        width: 100%;
        max-height: 120px;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        color: #333333;
        word-break: break-all;
        line-height: 14px;
      }
    }
    .external-notes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
      gap: 30px;
      .navigator-notes,
      .physician-notes {
        width: 100%;
        .note-heading {
          margin-bottom: 5px;
          font-size: 12px;
          font-family: "Mon-bold", sans-serif;
        }
        .value {
          width: 100%;
          max-height: 120px;
          overflow-y: auto;
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
          color: #333333;
          word-break: break-all;
          line-height: 14px;
        }
        .note-textarea-readonly {
          width: 100%;
          max-height: 120px;
          overflow-y: auto;
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
          color: #333333;
          word-break: break-all;
          line-height: 14px;
        }
      }
    }
    .internal-notes {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;
      width: 45%;
      .note-heading {
        margin-bottom: 5px;
        font-size: 12px;
        font-family: "Mon-bold", sans-serif;
      }
      .note-textarea-readonly {
        width: 100%;
        max-height: 120px;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        color: #333333;
        word-break: break-all;
        line-height: 14px;
      }
    }
  }
  .toc-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .toc-plan {
      width: 100%;
      margin-bottom: 16px;
      .care-plan-options-dropdown {
        width: 280px;
      }
      .options-heading {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 12px;
        color: #000;
        margin-bottom: 4px;
      }
      .option-box-wrapper {
        z-index: 3;
      }
      .toc-add-service-with-options {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .add-service-button {
          width: 129px;
          height: 40px;
          color: #333;
          text-align: center;
          font-family: "Mon-medium", sans-serif;
          font-size: 16px;
        }

        .toc-plan-los-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 8px;

          .toc-plan-los {
            display: flex;
            align-items: center;
            flex-direction: row;
          }

          .acute-los-heading {
            font-family: "Mon-semi-bold", sans-serif;
            font-size: 12px;
            color: #636d70;
            margin-right: 25px;
            margin-bottom: 4px;
          }

          .acute-los {
            .actue-los-input {
              background: #ffffff;
              mix-blend-mode: normal;
              opacity: 0.8;
              border: 1px solid #c1c9d2;
              border-radius: 8px;
              height: 41.25px;
              text-align: center;
              width: 60px;

              &.input-error {
                border: 1px solid #ff5757;
              }
            }
          }

          .acute-los-readonly {
            font-family: "Mon-medium", sans-serif;
            font-size: 14px;
            color: #000000;
            display: flex;
            justify-content: end;
          }
        }
      }
      .episode-details {
        .toc-plan-heading {
          font-size: 18px;
          font-family: "Mon-bold", sans-serif;
          // margin-bottom: 24px;
        }
        .toc-heading {
          display: flex;
          justify-content: space-between;
          // margin-bottom: 16px;
          .customise-care-plan-button {
            width: 201px;
            height: 40px;
            color: #333;
            text-align: center;
            font-family: "Mon-medium", sans-serif;
            font-size: 16px;
            &:disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }
          }
        }
        .toc-plan-options {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .toc-plan-los-container {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          // margin-bottom: 8px;
          .acute-los-heading {
            font-family: "Mon-medium", sans-serif;
            font-size: 12px;
            color: #636d70;
          }
          .acute-los-heading-sep {
            margin: 0 8px;
          }
          .acute-los {
            .actue-los-input {
              background: #ffffff;
              mix-blend-mode: normal;
              opacity: 0.8;
              border: 1px solid #c1c9d2;
              border-radius: 8px;
              height: 41.25px;
              text-align: center;
              width: 60px;
            }
            .input-error {
              border: 1px solid #ff5757;
            }
          }

          .acute-los-readonly {
            font-family: "Mon-medium", sans-serif;
            font-size: 14px;
            color: #000000;
            display: flex;
            justify-content: end;
          }
        }
      }
      .toc-plan-error {
        color: #ff5757;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        height: 30px;
        span {
          display: flex;
          margin-bottom: 10px;
          img {
            margin-right: 6.5px;
            height: 15px;
            width: 15px;
          }
        }
      }
    }
    .location-grid-container {
      min-width: 250px;
      width: 100%;
      margin-top: 8.6px;
      margin-bottom: 16px;
      .location-dropdowns {
        .location-row-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 17px;
          align-items: center;
          font-size: 12px;
          font-family: "Mon-bold", sans-serif;
          .location {
            width: 70px;
          }

          .location-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-left: 25px;
          }

          .preferred {
            color: #333;
            font-family: "Mon-medium", sans-serif !important;
            font-size: 12px;
          }
        }
        .read-only-footer {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 20px;
          .preferred-text {
            color: #333;
            font-family: "Mon-medium", sans-serif;
            font-size: 12px;
          }
          width: 100%;
          .preferred-container {
            display: flex;
            gap: 8px;
            align-items: center;
          }
        }
        .location-row {
          display: flex;
          flex-direction: row;
          margin-bottom: 17px;
          .location-value {
            font-size: 14px;
            color: #636d70;
            font-family: "Mon-medium", sans-serif;
            width: 70px;
          }
          .toc-dropdown-readonly {
            color: #333333;
            font-size: 12px;
            font-family: "Mon-medium", sans-serif;
            margin-left: 25px;
            display: flex;
            width: 100%;
            column-gap: 8px;
            min-width: 67%;
          }
          .toc-visits-readonly {
            color: #333333;
            font-size: 12px;
            font-family: "Mon-medium", sans-serif;
            margin-left: 95px;
            display: flex;
          }
        }
      }
      .checkbox-container {
        .home-with-no-service.disabled{
          opacity: .4;
        }
        .checkboxes {
          .checkbox-label {
            cursor: auto;
            .checkbox {
              cursor: auto;
            }
          }
        }
      }
      .view-changes-container {
        margin-top: 10px;
        .cta {
          font-family: "Mon-medium", sans-serif;
          color: #00887c;
          font-size: 12px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
.last-row-with-approve-button {
  height: 64px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: end;
  background: #eeeeee;
  padding-right: 32px;

  .cta-container {
    .clear-button,
    .cancel-button {
      font-family: "Mon-medium", sans-serif;
      color: #333333;
      padding: 10px;
      width: 122px;
      height: 40px;
      font-size: 16px;
      cursor: pointer;
      margin: 0px 10px;
      &:disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .submit-button {
      height: 40px;
      width: 146px;
      font-family: "Mon-medium", sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin-left: 10px;
      cursor: pointer;

      &:disabled {
        pointer-events: none;
      }
    }
  }
}
