.sortable-container {
  width: 100%;
  margin-bottom: 17px;

  .sortable-header {
    display: grid;
    grid-template-columns: 41px 140px 350px 1fr;
    justify-items: start;
    box-sizing: border-box;
    padding: 5px 10px;
    color: #333;
    font-family: "Mon-bold", sans-serif;
    font-size: 14px;
    line-height: normal;
    border-bottom: 1px solid #bcdfef;
    box-shadow: 0 0 0 1px #bcdfef;
    background: #f7fcfb;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px 10px 0 0;
    padding: 11px 8px;
    height:51px;
    align-items: center;
  }

  .sortable-row-container {
    width: 100%;
  }

  .legend-description {
    height: 42px;
    display: flex;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #ececec;
    border-left: 1px solid #ececec;
    transform: translateX(-1px);
    width: 100%;

    .preferred-legend {
      display: flex;
      align-items: center;
      margin-left: 16px;
      margin-right: 16px;
      column-gap: 8px;
      &-text {
        color: #333;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
      }
    }
    .privileged-legend {
      display: flex;
      align-items: center;
      column-gap: 8px;
      &-text {
        color: #333;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px
      }
    }
  }
}
