.add-service-popup {
  .modal-main {
    border-radius: 8px;
  }
}
.add-service-container {
  display: flex;
  flex-direction: column;
  width: 418px;
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 8px;
  .header {
    font-family: "Mon-bold", sans-serif;
    font-size: 16px;
    padding: 24px 0;
    color: #333333;
    align-self: center;
  }
  .content {
    font-family: "Mon-medium", sans-serif;
    font-size: 14px;
    color: #333333;
    padding: 0px 25px;

    .field {
      margin-bottom: 16px;
    }
    .result-dropdown-container {
      .option-box-wrapper.result-dropdown {
        width: 99%;
      }
      .icon-container {
        width: 33px;
        height: 33px;
      }
      .name-container .selected-value {
        color: #333;
        &.placeholder {
          opacity: 0.4;
        }
      }
    }

    .no-pointer-events {
      cursor: not-allowed;
      pointer-events: all;
      .icon-container {
        svg {
          cursor: not-allowed;
        }
      }
    }

    .heading {
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 12px;
      margin-bottom: 04px;
      color: #333333;
    }
    #single-date-picker-selector {
      width: fit-content;
    }
    .error-message {
      color: #ff5757;
      display: flex;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      line-height: normal;
      margin-top: 04px;
    }
    .acute-los {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
    .acute-los-input {
      border-radius: 8px;
      border: 1px solid #acc0bb;
      opacity: 0.8;
      background-color: #fff;
      display: flex;
      width: 90px;
      height: 39px;
      padding: 0 16px;

      &.error {
        border: 1px solid #ff5757;
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
  .button-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 24px 25px 19px 20px;
    .delete-button {
      cursor: pointer;
    }
    .cancel-button {
      font-family: "Mon-medium", sans-serif;
      color: #333333;
      padding: 10px;
      width: 122px;
      height: 40px;
      font-size: 16px;
      cursor: pointer;
      margin-right: 8px;
    }
    .reload-button {
      font-size: 16px;
      width: 122px;
      height: 40px;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
