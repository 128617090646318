.preview {
  border: 1px solid #bcdfef;
  background-color: #f1faf9;
}
.sortable-row {
  padding: 5px 10px;
  background: #fff;
  border-bottom: 1px solid #ececec;
  padding: 11px 8px;
  min-height: 50px;
  cursor: move;

  &.cursor-not-allowed {
    cursor: not-allowed;
  }

  .row-content {
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 41px 140px 350px 1.5fr 1fr 40px;
    box-sizing: border-box;
    justify-items: start;
    color: #333;
    text-overflow: ellipsis;
    font-family: "Mon-medium", sans-serif;
    font-size: 14px;
    align-items: center;

    .drag-icon {
      align-self: center;
      justify-self: center;
      user-select: none;
    }
    .services,
    .los {
      user-select: none;
    }
    .provider {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      user-select: none;
      &-name {
        padding-right: 10px;
      }
    }

    .pref-priv-images {
      display: flex;
      column-gap: 5px;
      height: 16px;
      align-items: center;
      div {
        display: flex;
      }
      .distance-container {
        color: #636d70;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        margin-left: 4px;
        .distance {
          font-family: "Mon-bold", sans-serif;
          padding-left: 5px;
        }
      }
    }

    .edit-button {
      display: flex;
      align-items: baseline;
      justify-self: flex-end;
      cursor: pointer;
      svg {
        padding-right: 8px;
      }
    }
  }

  &.animate {
    transition: all 0.1s;
    z-index: 1;
    border: 1px dashed #c1c9d2;
    opacity: 0.8;
    background: #f1f1f1;
  }

  &.opacity-1 {
    opacity: 1;
  }

  &.opacity-04 {
    opacity: 0.4 !important;
    pointer-events: none;
  }
}
